body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.active{
  background-color: yellow !important;
  color: green;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.sidebar-btn{
  padding-right: 5px;
}

.sidebar-btn button{
  width: 100%;
  margin-bottom: 5px;
}

.apexcharts-toolbar{
  z-index: 0 !important;
}

@media screen and (max-width: 600px) {
  label{
    font-size: 15px !important;
  }
  
}

.form-group{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
}

.form-group .single-select{
  width: 75%;
}

.form-group label{
  font-size: 1.1rem;
}

.btn-group{
  display: flex;
  justify-content: end;
  gap: 1rem;
}

.filter-block{
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 0.5rem;
}

.filter-block label{
  text-wrap: wrap;
  word-break: break-word;
  display: block;
}

.field-group{
  margin-top: 1rem;
}

.field-group h3{
  font-size: 20px;
  margin-left: 0.5rem;
  font-weight: 600;
}

.bottom-scroll-content{
  height: 70vh;
  overflow-y: scroll;
}

.bottom-scroll-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.bottom-scroll-content::-webkit-scrollbar-track {
  background: none; 
}

.bottom-scroll-content::-webkit-scrollbar-thumb {
  background: #8CC4FC; 
  border-radius: 10px;
}

.ppp-header{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  background-color: #E7E8EC;
  padding: 0.25rem;
  border-radius: 0.25rem;
}

.ppp-header .title{
  cursor: pointer;
}

.ppp-header .title span{
  color: #3498DB;
}

.ppp-content-block{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  height: 100%;
}

.ppp-content-block .ppp-table{
  width: 75%;
}

.ppp-content-block .application-list{
  width: 25%;
  background-color: #E7E8EC;
  padding: 1rem;
  border-radius: 0.50rem;
}

.ppp-content-block .application-list div{
  cursor: pointer;
}

.note-image{
  width: 2rem;
  cursor: pointer;
  margin: 0.25rem;
}

.list-group{
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.list-group h5{
  font-size: 1.10rem;
  font-weight: 600;
  margin-right: 0.25rem;
  width: 25%;
}

.yarl__slide_description{
  background: white !important;
  color: black !important;
  padding: 1rem !important;
}

.yarl__slide_wrapper img{
  object-fit: contain !important;
}